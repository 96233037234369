<template>
    <SelectDropdown :placeholder="placeholder">
        <div class="row body-style-dropdown-content">
            <div class="col-6 col-lg-4" v-for="(bodyStyle, $idx) in bodyStyles" :key="$idx">
                <box-check v-model="bodyStyle.selected" :label="bodyStyle.name"
                    @input="toggleBodyStyle(bodyStyle)"></box-check>
            </div>
        </div>
    </SelectDropdown>
</template>
<script>
const SelectDropdown = () => import("Components/SelectDropdown");
const BoxCheck = () => import("Components/BoxCheck");
export default {
    props: {
        value: Array,
    },
    mounted() {
        if (this.$store.state?.allBodyStyles?.length === 0) {
            this.$store.dispatch("fetchAllBodyStyles").then(() => {
                this.bodyStyles = this.mapBodyStyles();
                this.$emit("input", this.selectedBodyStyles);
            })
        }
        else {
            this.bodyStyles = this.mapBodyStyles();
            this.$emit("input", this.selectedBodyStyles);
        }
    },
    data() {
        return {
            bodyStyles: []
        }
    },
    methods: {
        mapBodyStyles() {
            const bodyStyles = this.$store.state?.allBodyStyles?.map((x) => {
                return {
                    name: x,
                    selected: this.$store.state?.searchOptions?.bodyStyles?.findIndex((y) => y === x) > -1
                }
            }) || [];
            bodyStyles.push({ name: 'All', selected: bodyStyles.length === 0, selectAll: true });
            return bodyStyles
        },
        toggleBodyStyle(bodyStyle) {
            this.bodyStyles.forEach((x) => {
                if (bodyStyle.selectAll && !x.selectAll) {
                    x.selected = false;
                }
                else if (!bodyStyle.selectAll && x.selectAll) {
                    x.selected = false;
                }
            })
            this.$emit("input", this.selectedBodyStyles);
        },
    },
    computed: {
        placeholder() {
            if (this.selectedBodyStyles.length === 1) {
                return this.selectedBodyStyles[0];
            }
            return this.selectedBodyStyles.length > 0 ? this.selectedBodyStyles.length + " selected" : "All body styles"
        },
        selectedBodyStyles() {
            return this.bodyStyles.filter((x) => x.selected && !x.selectAll).map((x) => x.name);
        },


    },
    components: { BoxCheck, SelectDropdown },
}
</script>
<style lang="scss" scoped>
.body-style-dropdown-content {
    width: 455px;

    @include media-breakpoint-down(md) {
        width: 100%;
    }
}
</style>